import React, { forwardRef, useEffect, useImperativeHandle, useRef, useCallback, useState } from 'react';
import axios from 'axios';
import { API_CONFIG } from '../../config/constants';

const AudioPlayer = forwardRef(({ speakerId, transcript, isPlaying, currentTime, muted, projectId }, ref) => {
    const audioRef = useRef(null);
    const playAttemptRef = useRef(null);
    const audioUrlsRef = useRef({});
    const isEditingRef = useRef(false);
    const preloadingRef = useRef(false);
    const lastValidSegmentRef = useRef(null);
    const playPromiseRef = useRef(null);
    const isPlayingRef = useRef(isPlaying);
    const [currentSegment, setCurrentSegment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isCacheReady, setIsCacheReady] = useState(false);
    const [isPlayRequested, setIsPlayRequested] = useState(false);

    // Maintenir à jour la référence isPlayingRef
    useEffect(() => {
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);

    const cancelPlayAttempt = useCallback(() => {
        if (playAttemptRef.current) {
            clearTimeout(playAttemptRef.current);
            playAttemptRef.current = null;
        }
        setIsPlayRequested(false);
    }, []);

    const safePlay = useCallback(async () => {
        if (!audioRef.current || isEditingRef.current || isLoading || isPlayRequested) {
            return;
        }

        try {
            setIsPlayRequested(true);
            if (playPromiseRef.current) {
                await playPromiseRef.current;
            }

            // Double vérification avant de lancer play()
            if (!isPlayingRef.current || !audioRef.current) {
                return;
            }

            playPromiseRef.current = audioRef.current.play();
            await playPromiseRef.current;
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error("Error playing audio:", error);
            }
        } finally {
            playPromiseRef.current = null;
            setIsPlayRequested(false);
        }
    }, [isLoading, isPlayRequested]);

    const safePause = useCallback(async () => {
        if (!audioRef.current) return;

        try {
            cancelPlayAttempt();
            // S'assurer qu'il n'y a pas de play en cours
            if (playPromiseRef.current) {
                await playPromiseRef.current;
            }
            if (audioRef.current && !audioRef.current.paused) {
                audioRef.current.pause();
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error("Error pausing audio:", error);
            }
        } finally {
            playPromiseRef.current = null;
        }
    }, [cancelPlayAttempt]);

    const invalidateSegmentCache = useCallback((segmentFile) => {
        if (audioUrlsRef.current[segmentFile]) {
            delete audioUrlsRef.current[segmentFile];
            if (currentSegment?.segment_file === segmentFile) {
                lastValidSegmentRef.current = null;
            }
        }
    }, [currentSegment]);

    const preloadAudio = useCallback(async (segment, forceReload = false) => {
        if (!segment?.segment_file) return null;

        if (!forceReload && audioUrlsRef.current[segment.segment_file]) {
            return audioUrlsRef.current[segment.segment_file];
        }

        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No authentication token found');

            const response = await axios.get(
                `${API_CONFIG.BASE_URL}/get_file/${projectId}/${segment.segment_file}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data?.url) {
                audioUrlsRef.current[segment.segment_file] = response.data.url;
                return response.data.url;
            }
            throw new Error("Invalid response format");
        } catch (error) {
            if (error?.response?.status === 404) {
                delete audioUrlsRef.current[segment.segment_file];
                console.warn(`File not found, removed from cache: ${segment.segment_file}`);
            }
            throw error;
        }
    }, [projectId]);

    const findSegmentAtTime = useCallback((time) => {
        if (!transcript) return null;

        const segment = transcript
            .filter(t => t.speaker === `SPEAKER_${speakerId}` && t.segment_file)
            .find(t => {
                // Ajout d'une petite marge de tolérance pour éviter les problèmes de timing
                const startTime = t.start - 0.01;
                const endTime = t.end + 0.01;
                return time >= startTime && time < endTime;
            });

        // Si on trouve un segment, on le garde en référence
        if (segment) {
            lastValidSegmentRef.current = segment;
            return segment;
        }

        // Si on ne trouve pas de segment, on retourne null
        return null;
    }, [transcript, speakerId]);

    const loadAndPlaySegment = useCallback(async (segment, time, forceReload = false) => {
        if (!segment || isEditingRef.current) return;

        setIsLoading(true);
        await safePause();

        try {
            const isSegmentValid = transcript.some(t => t.segment_file === segment.segment_file);
            if (!isSegmentValid) {
                console.warn(`Segment ${segment.segment_file} no longer exists in transcript`);
                return;
            }

            const audioUrl = await preloadAudio(segment, forceReload);
            if (!audioUrl) {
                throw new Error('Failed to load audio');
            }

            if (audioRef.current) {
                const wasPlaying = isPlayingRef.current;
                audioRef.current.src = audioUrl;
                await audioRef.current.load();

                const relativeTime = time - segment.start;
                audioRef.current.currentTime = Math.max(0, relativeTime);
                audioRef.current.volume = 1;

                if (wasPlaying && isPlayingRef.current) {
                    await safePlay();
                }
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error("Error loading audio segment:", error);
                if (error?.response?.status === 404) {
                    invalidateSegmentCache(segment.segment_file);
                }
            }
        } finally {
            setIsLoading(false);
        }
    }, [safePlay, safePause, preloadAudio, transcript, invalidateSegmentCache]);

    const preloadAllAudios = useCallback(async () => {
        if (preloadingRef.current || !transcript) return;
        preloadingRef.current = true;

        const speakerSegments = transcript.filter(t =>
            t.speaker === `SPEAKER_${speakerId}` && t.segment_file
        );

        try {
            const chunkSize = 5;
            for (let i = 0; i < speakerSegments.length; i += chunkSize) {
                const chunk = speakerSegments.slice(i, i + chunkSize);
                await Promise.all(
                    chunk.map(async (segment) => {
                        try {
                            await preloadAudio(segment);
                        } catch (error) {
                            console.warn(`Failed to preload ${segment.segment_file}`, error);
                        }
                    })
                );
            }
        } catch (error) {
            console.error('Error during preload:', error);
        } finally {
            preloadingRef.current = false;
            setIsCacheReady(true);
        }
    }, [transcript, preloadAudio, speakerId]);

    // Effet initial pour précharger les segments
    useEffect(() => {
        if (!isCacheReady) {
            preloadAllAudios();
        }
    }, [preloadAllAudios, isCacheReady]);


    // Ajouter cette fonction pour mieux gérer les transitions entre segments
    const handleSegmentTransition = useCallback(async (newSegment) => {
        if (!newSegment || !audioRef.current) return;

        try {
            // Calculer si ce segment devrait être joué
            const shouldPlay = currentTime >= newSegment.start &&
                currentTime < newSegment.end;

            // Charger le nouveau segment
            await loadAndPlaySegment(newSegment, currentTime);

            // Mettre à jour l'état de lecture
            if (shouldPlay && isPlayingRef.current) {
                await safePlay();
            } else {
                await safePause();
            }
        } catch (error) {
            console.error("Error during segment transition:", error);
        }
    }, [currentTime, loadAndPlaySegment, safePlay, safePause]);


    // Effet pour gérer les changements de segment
    useEffect(() => {
        const segment = findSegmentAtTime(currentTime);

        if (segment?.segment_file !== currentSegment?.segment_file) {
            setCurrentSegment(segment);
            handleSegmentTransition(segment);
        } else if (segment && audioRef.current) {
            const relativeTime = currentTime - segment.start;
            if (Math.abs(audioRef.current.currentTime - relativeTime) > 0.1) {
                audioRef.current.currentTime = relativeTime;
            }
        }
    }, [currentTime, findSegmentAtTime, handleSegmentTransition, currentSegment]);

    // Effet pour gérer l'état de lecture
    useEffect(() => {
        let isMounted = true;

        const updatePlayState = async () => {
            if (!isMounted) return;

            try {
                if (isPlaying && currentSegment) {
                    // Vérifier si ce segment doit être joué maintenant
                    const shouldPlay = currentTime >= currentSegment.start &&
                        currentTime < currentSegment.end;

                    if (shouldPlay) {
                        await safePlay();
                    } else {
                        await safePause();
                    }
                } else {
                    await safePause();
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error("Error updating play state:", error);
                }
            }
        };

        updatePlayState();

        return () => {
            isMounted = false;
        };
    }, [isPlaying, currentSegment, currentTime, safePlay, safePause]);

    // Effet pour gérer le mute
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = muted;
        }
    }, [muted]);

    // Interface externe
    useImperativeHandle(ref, () => ({
        play: safePlay,
        pause: safePause,
        get currentTime() {
            return audioRef.current && currentSegment
                ? audioRef.current.currentTime + currentSegment.start
                : currentTime;
        },
        set currentTime(value) {
            // Géré par Timeline
        },
        get muted() {
            return audioRef.current?.muted ?? false;
        },
        set muted(value) {
            if (audioRef.current) {
                audioRef.current.muted = value;
            }
        },
        setEditing: (editing) => {
            isEditingRef.current = editing;
            if (editing && audioRef.current) {
                safePause();
            }
        },
        isCacheReady: () => isCacheReady,
        resetCache: () => {
            audioUrlsRef.current = {};
            setIsCacheReady(false);
            lastValidSegmentRef.current = null;
            preloadAllAudios();
        },
        invalidateCache: invalidateSegmentCache,
        reloadCurrentSegment: () => {
            const segment = findSegmentAtTime(currentTime);
            if (segment && (!currentSegment || segment.segment_file !== currentSegment.segment_file)) {
                loadAndPlaySegment(segment, currentTime, true);
            }
        }
    }));

    // Nettoyage
    useEffect(() => {
        return () => {
            cancelPlayAttempt();
            if (audioRef.current) {
                safePause();
                audioRef.current.src = '';
            }
        };
    }, [cancelPlayAttempt, safePause]);

    return <audio ref={audioRef} />;
});

export default AudioPlayer;