import { API_CONFIG } from '../config/constants';

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No authentication token found');
    }
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const projectService = {
    getAllProjects: async () => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/api/projects`, {
                method: 'GET',
                headers: getAuthHeader()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la récupération des projets');
            }

            const data = await response.json();
            return data.projects;
        } catch (error) {
            console.error('Error fetching projects:', error);
            if (error.message === 'No authentication token found') {
                window.location.href = '/login';
            }
            throw error;
        }
    },

    getLanguages: async () => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/api/languages`, {
                method: 'GET',
                headers: getAuthHeader()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la récupération des langages');
            }

            const data = await response.json();
            return data.languages;
        } catch (error) {
            console.error('Error fetching languages:', error);
            if (error.message === 'No authentication token found') {
                window.location.href = '/login';
            }
            throw error;
        }
    },

    createProject: async (projectData) => {
        try {
            if (!projectData.name) {
                throw new Error('Le nom du projet est requis');
            }

            // Créer un FormData pour envoyer les fichiers
            const formData = new FormData();
            formData.append('name', projectData.name);

            // Ajouter chaque langue sélectionnée
            projectData.languages.forEach(lang => {
                formData.append('languages', lang);
            });

            // Ajouter les fichiers
            if (projectData.files) {
                projectData.files.forEach(file => {
                    formData.append('files', file);
                });
            }

            // Les headers doivent être modifiés car nous envoyons un FormData
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/api/projects`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                    // Ne pas définir Content-Type, il sera automatiquement défini avec la boundary
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la création du projet');
            }

            return await response.json();
        } catch (error) {
            console.error('Error creating project:', error);
            if (error.message === 'No authentication token found') {
                window.location.href = '/login';
            }
            throw error;
        }
    },

    exportAudio: async (projectId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/export_audio/${projectId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                if (response.status === 404) {
                    throw new Error('Projet non trouvé');
                }
                const error = await response.json();
                throw new Error(error.error || "Échec de l'export audio");
            }

            // Récupérer le blob audio
            const blob = await response.blob();
            return blob;

        } catch (error) {
            console.error('Error exporting audio:', error);
            if (error.message === 'No authentication token found') {
                window.location.href = '/login';
            }
            throw error;
        }
    },

    deleteProject: async (groupId) => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/api/projects/${groupId}`, {
                method: 'DELETE',
                headers: getAuthHeader()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                if (response.status === 404) {
                    throw new Error('Groupe de projets non trouvé');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la suppression du groupe de projets');
            }

            return await response.json();
        } catch (error) {
            console.error('Error deleting project group:', error);
            throw error;
        }
    }


};