import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Box, Slider, IconButton, Typography, Tooltip } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import TranslateIcon from '@mui/icons-material/Translate';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import DeleteIcon from '@mui/icons-material/Delete';
import WaveformDisplay from './WaveForm';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { API_CONFIG } from '../../config/constants';

const TRACK_HEIGHT = 40;
const TIMELINE_PADDING = 10;
const CURSOR_WIDTH = 2;
const SPEAKER_LABEL_WIDTH = 110;
const TIMESCALE_HEIGHT = 22;

const PRIMARY_COLOR = '#6366f1';
const SECONDARY_COLOR = '#4f46e5';

function Timeline({ currentTime,
    duration,
    onSeek,
    transcript,
    selectedBlock,
    onBlockSelect,
    onTranscriptUpdate,
    mutedTracks,
    toggleMute,
    projectId,
    onSave,
    isVideoAudioEnabled,
    onResetAudioCache,
    handleAudioReload,
    toggleVideoAudio }) {
    const containerRef = useRef(null);
    const timelineRef = useRef(null);
    const [zoom, setZoom] = useState(2);
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [hoveredBlock, setHoveredBlock] = useState(null);
    const [draggingBlock, setDraggingBlock] = useState(null);
    const [dragStartX, setDragStartX] = useState(0);
    const [dragStartTime, setDragStartTime] = useState(0);
    const [isCutMode, setIsCutMode] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(currentTime);
    const [speakerOrder, setSpeakerOrder] = useState([]);
    const [showTranslatedText, setShowTranslatedText] = useState(true);
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const hasMovedRef = useRef(false);
    const waveformRefs = useRef({});

    useEffect(() => {
        // Extraire les speakers uniques du transcript
        const speakers = [...new Set(transcript.map(item => item.speaker))];

        // Trier les speakers en fonction de leur numéro
        const sortedSpeakers = speakers.sort((a, b) => {
            // Extraire les numéros des speakers (ex: "SPEAKER_1" -> 1)
            const numA = parseInt(a.split('_')[1]);
            const numB = parseInt(b.split('_')[1]);
            return numA - numB;
        });

        setSpeakerOrder(sortedSpeakers);

    }, [transcript]);

    const handleTimelineMouseMove = useCallback((event) => {
        if (timelineRef.current) {
            const rect = timelineRef.current.getBoundingClientRect();
            const clickX = event.clientX - rect.left;
            const totalWidth = timelineRef.current.scrollWidth;
            const visibleWidth = rect.width;
            const scrollLeft = timelineRef.current.scrollLeft;

            // Calculer le pourcentage total (en tenant compte du scroll)
            const totalPercent = ((scrollLeft + clickX) / totalWidth);
            const clickTime = totalPercent * duration;

            if (isCutMode || isMouseDown) {
                setCursorPosition(Math.max(0, Math.min(duration, clickTime)));
            }
        }
    }, [isCutMode, isMouseDown, duration]);

    const handleTimelineMouseDown = useCallback((event) => {
        setIsMouseDown(true);
        handleTimelineMouseMove(event);
    }, [handleTimelineMouseMove]);

    const handleTimelineMouseUp = useCallback(() => {
        setIsMouseDown(false);
    }, []);

    const handleTimelineMouseLeave = useCallback(() => {
        if (!isCutMode) {
            setCursorPosition(currentTime);
        }
        setHoveredBlock(null);
    }, [isCutMode, currentTime]);

    useEffect(() => {
        if (!isCutMode) {
            setCursorPosition(currentTime);
        }
    }, [isCutMode, currentTime]);

    useEffect(() => {
        if (timelineRef.current) {
            timelineRef.current.addEventListener('mousemove', handleTimelineMouseMove);
            timelineRef.current.addEventListener('mouseleave', handleTimelineMouseLeave);
            timelineRef.current.addEventListener('mousedown', handleTimelineMouseDown);
            document.addEventListener('mouseup', handleTimelineMouseUp);
        }
        return () => {
            if (timelineRef.current) {
                timelineRef.current.removeEventListener('mousemove', handleTimelineMouseMove);
                timelineRef.current.removeEventListener('mouseleave', handleTimelineMouseLeave);
                timelineRef.current.removeEventListener('mousedown', handleTimelineMouseDown);
            }
            document.removeEventListener('mouseup', handleTimelineMouseUp);
        };
    }, [handleTimelineMouseMove, handleTimelineMouseLeave, handleTimelineMouseDown, handleTimelineMouseUp]);

    const handleMouseDown = useCallback((event, block) => {
        if (!isCutMode) {
            event.stopPropagation();
            setDraggingBlock(block);
            setDragStartX(event.clientX);
            setDragStartTime(block.start);
            setIsDragging(true);
            hasMovedRef.current = false;
        }
    }, [isCutMode]);

    const handleMouseMove = useCallback((event) => {
        if (isMouseDown && draggingBlock && timelineRef.current) {
            const rect = timelineRef.current.getBoundingClientRect();
            const timelineWidth = rect.width;
            const visibleDuration = duration / zoom;
            const pixelsPerSecond = timelineWidth / visibleDuration;
            const deltaX = event.clientX - dragStartX;
            const deltaTime = deltaX / pixelsPerSecond;

            // Si le mouvement est significatif (plus de 2 pixels)
            if (Math.abs(deltaX) > 2) {
                hasMovedRef.current = true;
            }

            const newStart = Math.max(0, Math.min(duration - (draggingBlock.end - draggingBlock.start), dragStartTime + deltaTime));
            const newEnd = newStart + (draggingBlock.end - draggingBlock.start);

            const updatedTranscript = transcript.map(t =>
                t.text === draggingBlock.text ? { ...t, start: newStart, end: newEnd } : t
            );

            onTranscriptUpdate(updatedTranscript);

            if (event.clientX < rect.left + 20) {
                timelineRef.current.scrollLeft -= 10;
            } else if (event.clientX > rect.right - 20) {
                timelineRef.current.scrollLeft += 10;
            }
        }
    }, [draggingBlock, dragStartX, dragStartTime, duration, zoom, transcript, onTranscriptUpdate]);

    const handleMouseUp = useCallback(() => {
        if (draggingBlock && isDragging && hasMovedRef.current) {
            onSave();
        }
        setDraggingBlock(null);
        setIsDragging(false);
        hasMovedRef.current = false;
    }, [draggingBlock, isDragging, onSave]);

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseMove, handleMouseUp]);

    const handleCutModeToggle = () => {
        setIsCutMode(!isCutMode);
    };


    // Utilitaire pour comparer deux segments
    const areSegmentsEqual = (seg1, seg2) => {
        return seg1.start === seg2.start &&
            seg1.end === seg2.end &&
            seg1.speaker === seg2.speaker &&
            seg1.segment_file === seg2.segment_file;
    };

    const findModifiedSegments = (oldTranscript, newTranscript) => {
        // Trouver spécifiquement les segments affectés par le merge
        const modifiedSegments = [];

        // 1. Trouver les deux segments qui ont été fusionnés (ils n'existeront plus dans newTranscript)
        const removedSegments = oldTranscript.filter(oldSeg =>
            !newTranscript.some(newSeg => newSeg.segment_file === oldSeg.segment_file)
        );

        // 2. Trouver le nouveau segment fusionné (il n'existera pas dans oldTranscript)
        const newSegments = newTranscript.filter(newSeg =>
            !oldTranscript.some(oldSeg => oldSeg.segment_file === newSeg.segment_file)
        );

        // 3. Ajouter uniquement ces segments spécifiques aux segments modifiés
        modifiedSegments.push(...newSegments);

        console.log("Segments fusionnés:", modifiedSegments);
        return modifiedSegments;
    };

    const handleTimelineClick = (event) => {
        if (timelineRef.current) {
            const rect = timelineRef.current.getBoundingClientRect();
            const clickX = event.clientX - rect.left;
            const clickY = event.clientY - rect.top;
            const totalWidth = timelineRef.current.scrollWidth;
            const scrollLeft = timelineRef.current.scrollLeft;

            // Utiliser la même logique de calcul que handleTimelineMouseMove
            const totalPercent = ((scrollLeft + clickX) / totalWidth);
            const clickTime = totalPercent * duration;

            const trackIndex = Math.floor((clickY - TIMELINE_PADDING - TIMESCALE_HEIGHT) / TRACK_HEIGHT);
            const speaker = speakerOrder[trackIndex];

            if (isCutMode) {
                performCut(clickTime, speaker);
            } else {
                const clickedBlock = transcript
                    .filter(t => t.speaker === speaker)
                    .find(t => clickTime >= t.start && clickTime < t.end);

                if (clickedBlock) {
                    onBlockSelect(clickedBlock);
                } else {
                    onBlockSelect(null);
                    setSelectedBlocks([]);
                }
            }

            onSeek(Math.max(0, Math.min(duration, clickTime)));
        }
    };

    const performCut = async (cutTime, speaker) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/cut_audio/${projectId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    transcript: transcript,
                    cutTime: cutTime,
                    speaker: speaker,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Network response was not ok');
            }

            const result = await response.json();

            // Identifier uniquement les segments modifiés
            const modifiedSegments = findModifiedSegments(transcript, result.updatedTranscript);

            // Mettre à jour le transcript complet
            onTranscriptUpdate(result.updatedTranscript);

            // Recharger uniquement les segments modifiés
            const uniqueSpeakersToReload = [...new Set(modifiedSegments.map(seg => seg.speaker))];
            uniqueSpeakersToReload.forEach(speaker => {
                const speakerSegments = modifiedSegments.filter(seg => seg.speaker === speaker);
                speakerSegments.forEach(segment => {
                    if (handleAudioReload) {
                        handleAudioReload(speaker, segment);
                    }
                });
            });

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getColor = (speaker) => {
        const colors = ["#4B90F5", "#45C75E", "#F5A623", "#FF6B6B"];
        const speakerIndex = speakerOrder.indexOf(speaker);
        return colors[speakerIndex % colors.length];
    };

    const handleZoomChange = (event, newValue) => {
        const oldZoom = zoom;
        setZoom(newValue);
        if (timelineRef.current) {
            const visibleCenter = scrollPercentage + (1 / (2 * oldZoom));
            const newScrollPercentage = visibleCenter - (1 / (2 * newValue));
            setScrollPercentage(Math.max(0, Math.min(1 - (1 / newValue), newScrollPercentage)));
            timelineRef.current.scrollLeft = newScrollPercentage * timelineRef.current.scrollWidth;
        }
    };

    const handleScroll = (event) => {
        const maxScroll = event.target.scrollWidth - event.target.clientWidth;
        setScrollPercentage(event.target.scrollLeft / maxScroll);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        const ms = Math.floor((seconds % 1) * 100);
        return `${mins}:${secs.toString().padStart(2, '0')}.${ms.toString().padStart(2, '0')}`;
    };

    const renderTimeScale = () => {
        const visibleDuration = duration / zoom;

        // Ajuster l'intervalle en fonction du zoom
        let interval;
        if (visibleDuration <= 30) {
            interval = 1;
        } else if (visibleDuration <= 60) {
            interval = 5;
        } else if (visibleDuration <= 300) {
            interval = 15;
        } else if (visibleDuration <= 900) {
            interval = 30;
        } else {
            interval = 60;
        }

        const timeScaleMarkers = [];
        const numMarkers = Math.ceil(duration / interval);
        const minSpaceBetweenLabels = 50; // Espace minimum en pixels entre les labels
        const timelineWidth = timelineRef.current?.clientWidth || 1000;
        const pixelsPerPercent = timelineWidth / 100;

        for (let i = 0; i <= numMarkers; i++) {
            const time = i * interval;
            if (time <= duration) {
                const left = `${(time / duration) * 100}%`;
                const leftPixels = (time / duration) * 100 * pixelsPerPercent * zoom;

                // Vérifier si ce marqueur serait trop proche du précédent
                const previousMarkerPixels = ((i - 1) * interval / duration) * 100 * pixelsPerPercent * zoom;
                const shouldShow = i === 0 || (leftPixels - previousMarkerPixels) >= minSpaceBetweenLabels;

                timeScaleMarkers.push(
                    <Box
                        key={time}
                        sx={{
                            position: 'absolute',
                            left: left,
                            transform: 'translateX(-50%)',
                            marginTop: 1,
                            height: TIMESCALE_HEIGHT + 1,
                            display: shouldShow ? 'flex' : 'none',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: '0.65rem',
                                color: 'rgba(255,255,255,0.7)',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {formatTime(time)}
                        </Typography>
                        <Box
                            sx={{
                                width: 1,
                                height: 5,
                                bgcolor: 'rgba(255,255,255,0.5)',
                                marginTop: '2px'
                            }}
                        />
                    </Box>
                );
            }
        }

        return (
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                {timeScaleMarkers}
            </Box>
        );
    };

    const toggleTranslatedText = () => {
        setShowTranslatedText(!showTranslatedText);
    };

    const lightenColor = (color, amount) => {
        return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    };

    const handleBlockSelect = (block, event) => {
        if (event.ctrlKey || event.metaKey) {
            setSelectedBlocks(prev => {
                if (prev.includes(block)) {
                    return prev.filter(b => b !== block);
                } else {
                    return [...prev, block].slice(-2);
                }
            });
        } else {
            setSelectedBlocks([block]);
        }
        onBlockSelect(block);
    };

    const handleDeleteClick = useCallback(async (e) => {
        e.stopPropagation();
        let updatedTranscript = transcript;
        if (selectedBlocks.length > 0) {
            updatedTranscript = transcript.filter(block => !selectedBlocks.includes(block));
            onTranscriptUpdate(updatedTranscript);
            setSelectedBlocks([]);

            try {
                await fetch(`${API_CONFIG.BASE_URL}/delete_segment/${projectId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({ segments: selectedBlocks })
                });
            } catch (error) {
                console.error('Error deleting segments:', error);
            }
        } else if (selectedBlock) {
            updatedTranscript = transcript.filter(block => block !== selectedBlock);
            onTranscriptUpdate(updatedTranscript);
            onBlockSelect(null);

            try {
                await fetch(`${API_CONFIG.BASE_URL}/delete_segment/${projectId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({ segments: [selectedBlock] })
                });
            } catch (error) {
                console.error('Error deleting segment:', error);
            }
        }
        await onSave(updatedTranscript);

    }, [transcript, selectedBlocks, selectedBlock, onTranscriptUpdate, onBlockSelect, projectId]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Delete') {
                handleDeleteClick(event);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleDeleteClick]);

    const handleMergeBlocks = async () => {
        if (selectedBlocks.length !== 2) {
            alert("Veuillez sélectionner exactement deux blocs à fusionner.");
            return;
        }

        const [block1, block2] = selectedBlocks;

        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/merge_blocks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    project_id: projectId,
                    transcript: transcript,
                    block1SegmentFile: block1.segment_file,
                    block2SegmentFile: block2.segment_file
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            // Identifier uniquement le nouveau segment fusionné
            const modifiedSegments = findModifiedSegments(transcript, result.updatedTranscript);

            // Mettre à jour le transcript complet
            onTranscriptUpdate(result.updatedTranscript);

            // Recharger uniquement le nouveau segment fusionné
            if (modifiedSegments.length > 0) {
                const newSegment = modifiedSegments[0]; // Il n'y aura qu'un seul nouveau segment
                if (handleAudioReload) {
                    handleAudioReload(newSegment.speaker, newSegment);
                }
            }

        } catch (error) {
            console.error('Error:', error);
            alert("Une erreur s'est produite lors de la fusion des blocs.");
        }
    };

    const renderBlock = (block, index, speaker) => {
        const isInSelection = selectedBlocks.includes(block);
        const isHovered = hoveredBlock === block;
        const left = `${(block.start / duration) * 100}%`;
        const width = `${((block.end - block.start) / duration) * 100}%`;
        const baseColor = getColor(speaker);
        const color = isInSelection ? '#6366f1' : isHovered ? lightenColor(PRIMARY_COLOR, 40) : baseColor;
        const borderColor = lightenColor(color, 40);
        const textToShow = showTranslatedText ? block.translated_text : block.text;

        return (
            <Tooltip key={index} title={textToShow} placement="top">
                <Box
                    sx={{
                        position: 'absolute',
                        left: left,
                        width: width,
                        height: '100%',
                        bgcolor: color,
                        cursor: isCutMode ? 'crosshair' : 'move',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        overflow: 'hidden',
                        border: `1px solid ${borderColor}`,
                        boxSizing: 'border-box',
                        borderRadius: '2px',
                        boxShadow: isInSelection ? '0 0 0 1px rgba(255,255,255,0.5)' : 'none',
                        '&:hover': {
                            boxShadow: '0 0 0 1px rgba(255,255,255,0.7)',
                        },
                    }}
                    onMouseDown={(e) => handleMouseDown(e, block)}
                    onClick={(e) => handleBlockSelect(block, e)}
                    onMouseEnter={() => setHoveredBlock(block)}
                    onMouseLeave={() => setHoveredBlock(null)}
                >
                    <WaveformDisplay
                        ref={(el) => {
                            const key = `${block.speaker}-${block.segment_file}`;
                            if (!waveformRefs.current) waveformRefs.current = {};
                            waveformRefs.current[key] = el;
                        }}
                        waveformFile={block.waveform}
                        color={color}
                        projectId={projectId}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            padding: '2px',
                            fontSize: '10px',
                            color: 'white',
                            textShadow: '0px 0px 2px rgba(0,0,0,0.7)',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                        }}
                    >
                        {textToShow}
                    </Box>
                </Box>
            </Tooltip>
        );
    };

    return (
        <Box ref={containerRef} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', bgcolor: '#1E1E1E', userSelect: 'none' }}>
            <Box sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
                <Box sx={{ width: SPEAKER_LABEL_WIDTH + 20, flexShrink: 0, bgcolor: '#2D2D2D' }}>
                    <Box sx={{ marginTop: '10px', height: TIMESCALE_HEIGHT, borderBottom: '1px solid #3A3A3A' }} />
                    {speakerOrder.map((speaker) => (
                        <Box
                            key={speaker}
                            sx={{
                                height: TRACK_HEIGHT,
                                display: 'flex',
                                alignItems: 'center',
                                pl: 1,
                                borderBottom: '1px solid #3A3A3A',
                                borderRight: '1px solid #3A3A3A'
                            }}
                        >
                            <Typography variant="subtitle2" sx={{ ml: 1, color: 'rgba(255,255,255,0.8)', fontSize: '0.8rem' }}>{speaker}</Typography>
                            <IconButton onClick={() => toggleMute(speaker)} size="small" sx={{ color: 'rgba(255,255,255,0.6)' }}>
                                {mutedTracks[speaker] ? <VolumeOffIcon fontSize="small" /> : <VolumeUpIcon fontSize="small" />}
                            </IconButton>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
                    <Box
                        ref={timelineRef}
                        sx={{
                            width: '100%',
                            height: '100%',
                            bgcolor: '#2D2D2D',
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            '&::-webkit-scrollbar': {
                                height: '10px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#555',
                                borderRadius: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#333',
                                borderRadius: '5px',
                            },
                        }}
                        onScroll={handleScroll}
                        onClick={handleTimelineClick}
                    >
                        <Box sx={{ width: `${100 * zoom}%`, height: '100%', position: 'relative' }}>
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: TIMESCALE_HEIGHT + 10,
                                borderBottom: '1px solid #3A3A3A',
                                zIndex: 1
                            }}>
                                {renderTimeScale()}
                            </Box>
                            {speakerOrder.map((speaker, speakerIndex) => (
                                <Box
                                    key={speaker}
                                    sx={{
                                        position: 'absolute',
                                        top: speakerIndex * TRACK_HEIGHT + TIMELINE_PADDING + TIMESCALE_HEIGHT,
                                        left: 0,
                                        width: '100%',
                                        height: TRACK_HEIGHT,
                                        bgcolor: '#252525',
                                        borderBottom: '1px solid #3A3A3A',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {transcript.filter(t => t.speaker === speaker).map((t, tIndex) => renderBlock(t, tIndex, speaker))}
                                </Box>
                            ))}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: `${(cursorPosition / duration) * 100}%`,
                                    top: 0,
                                    bottom: 0,
                                    width: CURSOR_WIDTH,
                                    bgcolor: isCutMode ? '#FF6B6B' : PRIMARY_COLOR,
                                    opacity: '0.8',
                                    pointerEvents: 'none',
                                    zIndex: 2,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', p: 1, bgcolor: '#2D2D2D' }}>
                <IconButton size="small" onClick={() => handleZoomChange(null, Math.max(1, zoom - 1))} sx={{ color: 'rgba(255,255,255,0.7)' }}>
                    <ZoomOutIcon fontSize="small" />
                </IconButton>
                <Slider
                    value={zoom}
                    onChange={handleZoomChange}
                    min={1}
                    max={8}
                    step={0.1}
                    sx={{
                        mx: 1,
                        width: 100,
                        color: PRIMARY_COLOR,
                        '& .MuiSlider-thumb': {
                            width: 12,
                            height: 12,
                        },
                        '& .MuiSlider-rail': {
                            opacity: 0.3,
                        },
                    }}
                />
                <IconButton size="small" onClick={() => handleZoomChange(null, Math.min(8, zoom + 1))} sx={{ color: 'rgba(255,255,255,0.7)' }}>
                    <ZoomInIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={handleCutModeToggle} sx={{ ml: 2, color: isCutMode ? '#FF6B6B' : 'rgba(255,255,255,0.7)' }}>
                    <ContentCutIcon fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={handleDeleteClick}
                    disabled={!selectedBlock && selectedBlocks.length === 0}
                    sx={{
                        ml: 2,
                        color: selectedBlock || selectedBlocks.length > 0 ? 'rgba(255,255,255,0.7)' : 'rgba(255,255,255,0.3)',
                        '&.Mui-disabled': {
                            color: 'rgba(255,255,255,0.3)',
                        }
                    }}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={toggleTranslatedText} sx={{ ml: 2, color: showTranslatedText ? PRIMARY_COLOR : 'rgba(255,255,255,0.7)' }}>
                    <TranslateIcon fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={handleMergeBlocks}
                    disabled={selectedBlocks.length !== 2}
                    sx={{
                        ml: 2,
                        color: selectedBlocks.length === 2 ? PRIMARY_COLOR : 'rgba(255,255,255,0.3)',
                        '&.Mui-disabled': {
                            color: 'rgba(255,255,255,0.3)',
                        }
                    }}
                >
                    <MergeTypeIcon fontSize="small" />
                </IconButton>
                <Box
                    onClick={toggleVideoAudio}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: 2,
                        cursor: 'pointer',
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                        color: 'rgba(255,255,255,0.7)',
                        borderRadius: '20px',
                        padding: '2px 10px',
                        transition: 'all 0.3s ease',
                    }}
                >
                    {isVideoAudioEnabled ? <PersonIcon fontSize="small" /> : <SmartToyIcon fontSize="small" />}
                    <Typography
                        variant="caption"
                        sx={{
                            ml: 0.8,
                            mt: 0.30,
                            fontSize: '0.70rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {isVideoAudioEnabled ? 'Original voice' : 'IA voice'}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default Timeline;