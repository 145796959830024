import React, { useState, useEffect } from 'react';
import './FiltersModal.css';

const FiltersModal = ({ isOpen, onClose, filters, onFiltersChange, languages }) => {
    // État local pour les filtres temporaires
    const [tempFilters, setTempFilters] = useState({
        statuses: [],
        languages: [],
        dateRange: {
            start: '',
            end: ''
        },
        duration: {
            min: '',
            max: ''
        }
    });

    // Initialiser les filtres temporaires quand le modal s'ouvre
    useEffect(() => {
        if (isOpen) {
            setTempFilters({
                statuses: filters.statuses || [],
                languages: filters.languages || [],
                dateRange: filters.dateRange || { start: '', end: '' },
                duration: filters.duration || { min: '', max: '' }
            });
        }
    }, [isOpen, filters]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleStatusToggle = (status) => {
        setTempFilters(prev => ({
            ...prev,
            statuses: prev.statuses.includes(status)
                ? prev.statuses.filter(s => s !== status)
                : [...prev.statuses, status]
        }));
    };

    const handleLanguageToggle = (language) => {
        setTempFilters(prev => ({
            ...prev,
            languages: prev.languages.includes(language)
                ? prev.languages.filter(l => l !== language)
                : [...prev.languages, language]
        }));
    };

    const handleDateChange = (field, value) => {
        setTempFilters(prev => ({
            ...prev,
            dateRange: {
                ...prev.dateRange,
                [field]: value
            }
        }));
    };

    const handleDurationChange = (field, value) => {
        setTempFilters(prev => ({
            ...prev,
            duration: {
                ...prev.duration,
                [field]: value
            }
        }));
    };

    const clearFilters = () => {
        const emptyFilters = {
            statuses: [],
            languages: [],
            dateRange: { start: '', end: '' },
            duration: { min: '', max: '' }
        };
        setTempFilters(emptyFilters);
        onFiltersChange(emptyFilters);
    };

    const handleApply = () => {
        onFiltersChange({
            ...filters,
            ...tempFilters
        });
        onClose();
    };

    const getActiveFiltersCount = () => {
        let count = tempFilters.statuses.length + tempFilters.languages.length;
        if (tempFilters.dateRange.start || tempFilters.dateRange.end) count++;
        if (tempFilters.duration.min || tempFilters.duration.max) count++;
        return count;
    };

    if (!isOpen) return null;

    return (
        <div className="filters-modal-backdrop" onClick={onClose}>
            <div className="filters-modal" onClick={e => e.stopPropagation()}>
                <div className="filters-modal__header">
                    <h2 className="filters-modal__title">
                        <i className="fas fa-filter"></i>
                        Filtrer les projets
                    </h2>
                    <button className="filters-modal__close" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>



                <div className="filters-modal__content">


                    {/* Section Date */}
                    <div className="filters-modal__section">
                        <h3 className="filters-modal__section-title">
                            <i className="fas fa-calendar"></i>
                            Date de création
                        </h3>
                        <div className="filters-modal__date-inputs">
                            <div className="input-group">
                                <label>Du</label>
                                <input
                                    type="date"
                                    value={tempFilters.dateRange.start}
                                    onChange={(e) => handleDateChange('start', e.target.value)}
                                    className="filters-modal__input"
                                />
                            </div>
                            <div className="input-group">
                                <label>Au</label>
                                <input
                                    type="date"
                                    value={tempFilters.dateRange.end}
                                    onChange={(e) => handleDateChange('end', e.target.value)}
                                    className="filters-modal__input"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Section Durée */}
                    <div className="filters-modal__section">
                        <h3 className="filters-modal__section-title">
                            <i className="fas fa-clock"></i>
                            Durée (minutes)
                        </h3>
                        <div className="filters-modal__duration-inputs">
                            <div className="input-group">
                                <label>Min</label>
                                <input
                                    type="number"
                                    min="0"
                                    value={tempFilters.duration.min ? Math.floor(tempFilters.duration.min / 60) : ''}
                                    onChange={(e) => handleDurationChange('min', e.target.value ? e.target.value * 60 : '')}
                                    className="filters-modal__input"
                                    placeholder="0"
                                />
                            </div>
                            <div className="input-group">
                                <label>Max</label>
                                <input
                                    type="number"
                                    min="0"
                                    value={tempFilters.duration.max ? Math.floor(tempFilters.duration.max / 60) : ''}
                                    onChange={(e) => handleDurationChange('max', e.target.value ? e.target.value * 60 : '')}
                                    className="filters-modal__input"
                                    placeholder="∞"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Section Status */}
                    <div className="filters-modal__section">
                        <h3 className="filters-modal__section-title">
                            <i className="fas fa-chart-line"></i>
                            Status
                        </h3>
                        <div className="filters-modal__options">
                            <button
                                className={`filters-modal__option ${tempFilters.statuses.includes('progress') ? 'active' : ''}`}
                                onClick={() => handleStatusToggle('progress')}
                            >
                                <i className="fas fa-clock"></i>
                                En traitement
                                <i className={`fas fa-check filters-modal__check ${tempFilters.statuses.includes('progress') ? 'visible' : ''}`}></i>
                            </button>
                            <button
                                className={`filters-modal__option ${tempFilters.statuses.includes('completed') ? 'active' : ''}`}
                                onClick={() => handleStatusToggle('completed')}
                            >
                                <i className="fas fa-check-circle"></i>
                                Terminé
                                <i className={`fas fa-check filters-modal__check ${tempFilters.statuses.includes('completed') ? 'visible' : ''}`}></i>
                            </button>
                            <button
                                className={`filters-modal__option ${tempFilters.statuses.includes('failed') ? 'active' : ''}`}
                                onClick={() => handleStatusToggle('failed')}
                            >
                                <i className="fas fa-times-circle"></i>
                                Échoué
                                <i className={`fas fa-check filters-modal__check ${tempFilters.statuses.includes('failed') ? 'visible' : ''}`}></i>
                            </button>
                        </div>
                    </div>

                    {/* Section Langues */}
                    <div className="filters-modal__section">
                        <h3 className="filters-modal__section-title">
                            <i className="fas fa-language"></i>
                            Langues
                        </h3>
                        <div className="filters-modal__options filters-modal__options--grid">
                            {languages.map(lang => (
                                <button
                                    key={lang.code}
                                    className={`filters-modal__option ${tempFilters.languages.includes(lang.code) ? 'active' : ''}`}
                                    onClick={() => handleLanguageToggle(lang.code)}
                                >
                                    {lang.name}
                                    <i className={`fas fa-check filters-modal__check ${tempFilters.languages.includes(lang.code) ? 'visible' : ''}`}></i>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="filters-modal__footer">
                    <button
                        className="filters-modal__clear"
                        onClick={clearFilters}
                        disabled={getActiveFiltersCount() === 0}
                    >
                        <i className="fas fa-trash-alt"></i>
                        Effacer les filtres
                    </button>
                    <button className="filters-modal__apply" onClick={handleApply}>
                        Appliquer
                        {getActiveFiltersCount() > 0 && (
                            <span className="filters-modal__count">
                                {getActiveFiltersCount()}
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FiltersModal;