// src/config/constants.js
export const API_CONFIG = {
    // BASE_URL: 'http://localhost:5000',
    BASE_URL: 'https://sumfy.fr:5000',
    ENDPOINTS: {
        LOGIN: '/api/login',
        REGISTER: '/api/register',
        PROJECTS: '/api/projects'
    }
};
